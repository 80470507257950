"use strict";

var _interopRequireDefault = require("D:/www/voting/back-end/client/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkPay = checkPay;
exports.create = create;
exports.getInfo = getInfo;
exports.getList = getList;
exports.getOwner = getOwner;
exports.getPage = getPage;
exports.inviteCode = inviteCode;
exports.join = join;
exports.login = login;
exports.quit = quit;
exports.register = register;
exports.remove = remove;
exports.update = update;
exports.updateOwner = updateOwner;
exports.updateStatus = updateStatus;
exports.upgradeCode = upgradeCode;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/page',
    method: 'get',
    params: params
  });
}
function getList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/list',
    method: 'get',
    params: params
  });
}
function getInfo(id) {
  return (0, _request.default)({
    url: '/firm/pos/info',
    method: 'get',
    params: {
      id: id
    }
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/firm/pos/create',
    method: 'post',
    data: data
  });
}
function update(data) {
  return (0, _request.default)({
    url: '/firm/pos/update',
    method: 'post',
    data: data
  });
}
function remove(data) {
  return (0, _request.default)({
    url: '/firm/pos/remove',
    method: 'post',
    data: data
  });
}
function updateStatus(data) {
  return (0, _request.default)({
    url: '/firm/pos/updateStatus',
    method: 'post',
    data: data
  });
}
function register() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/register',
    method: 'get',
    params: params
  });
}
function login() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/login',
    method: 'get',
    params: params
  });
}
function join() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/join',
    method: 'get',
    params: params
  });
}
function quit() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/quit',
    method: 'get',
    params: params
  });
}
function getOwner() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/owner',
    method: 'get',
    params: params
  });
}
function updateOwner(data) {
  return (0, _request.default)({
    url: '/firm/pos/updateOwner',
    method: 'post',
    data: data
  });
}
function upgradeCode() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/upgradeCode',
    method: 'get',
    params: params
  });
}
function inviteCode() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/pos/inviteCode',
    method: 'get',
    params: params
  });
}
function checkPay() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/order/checkPay',
    method: 'get',
    params: params
  });
}