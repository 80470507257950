"use strict";

var _interopRequireDefault = require("D:/www/voting/back-end/client/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/firm/grade/list',
    method: 'get',
    data: params
  });
}