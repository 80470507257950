var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex align-center justify-center",
      staticStyle: { "padding-top": "200px" },
    },
    [
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "title" }, [_vm._v("选择门店")]),
        _c("div", { staticClass: "tip" }, [
          _vm._v("一个账户可创建或加入多个门店"),
        ]),
        _c("div", { staticClass: "line" }),
        _c(
          "div",
          { staticClass: "margin-top" },
          [
            _vm._l(_vm.owner, function (item) {
              return _c("div", { key: item.id, staticClass: "owner-item" }, [
                _c("div", { staticClass: "flex align-center" }, [
                  item.image
                    ? _c("img", {
                        attrs: { src: item.image && item.image.url, alt: "" },
                      })
                    : _c("i", {
                        staticClass: "el-icon-house",
                        staticStyle: { "font-size": "58px", color: "#999" },
                      }),
                  _c(
                    "div",
                    { staticClass: "margin-left-sm" },
                    [
                      _c("div", { staticClass: "text-lg" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      item.owner_id == _vm.user.id
                        ? _c(
                            "el-tag",
                            {
                              staticClass: "margin-right-sm",
                              attrs: {
                                type: "primary",
                                size: "mini",
                                effect: "dark",
                              },
                            },
                            [_vm._v("我的")]
                          )
                        : _vm._e(),
                      _c(
                        "el-tag",
                        {
                          staticClass: "margin-right-sm",
                          attrs: { type: "success", size: "mini" },
                        },
                        [_vm._v(_vm._s(item.grade_title || "普通门店"))]
                      ),
                      item.expire_status
                        ? _c(
                            "el-tag",
                            {
                              staticClass: "margin-right-sm",
                              attrs: { type: "danger", size: "mini" },
                            },
                            [_vm._v(_vm._s("已到期"))]
                          )
                        : item.expire_day > 0 && item.expire_day < 30
                        ? _c(
                            "el-tag",
                            {
                              staticClass: "margin-right-sm",
                              attrs: { type: "danger", size: "mini" },
                            },
                            [_vm._v(_vm._s(item.expire_day) + "天后到期")]
                          )
                        : _vm._e(),
                      item.owner_id != _vm.user.id
                        ? _c(
                            "el-link",
                            {
                              staticClass: "text-sm",
                              attrs: { underline: true, type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleQuit(item.id)
                                },
                              },
                            },
                            [_vm._v("退出")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "flex align-center pointer",
                    on: {
                      click: function ($event) {
                        return _vm.handleLogin(item.id)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text-grey" }, [_vm._v("进入")]),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
              ])
            }),
            _vm.firm.register_enable
              ? _c(
                  "div",
                  {
                    staticClass: "owner-item owner-create pointer",
                    on: {
                      click: function ($event) {
                        _vm.createVisible = true
                      },
                    },
                  },
                  [_vm._m(0), _vm._m(1)]
                )
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "flex align-center justify-between margin-top" },
          [
            _c(
              "div",
              { staticClass: "flex align-center" },
              [
                _c("span", { staticClass: "margin-right-xs" }, [
                  _vm._v("已有门店？"),
                ]),
                _c(
                  "el-link",
                  {
                    attrs: { underline: false, type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.joinVisible = true
                      },
                    },
                  },
                  [_vm._v("去加入")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex align-center" },
              [
                _c(
                  "el-link",
                  {
                    attrs: { underline: false, type: "primary" },
                    on: { click: _vm.handleLogout },
                  },
                  [_vm._v("切换账号")]
                ),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { width: "600px", visible: _vm.joinVisible },
          on: {
            "update:visible": function ($event) {
              _vm.joinVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "dialog-title" }, [_vm._v("加入门店")]),
          ]),
          _c(
            "el-form",
            { ref: "form" },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "code" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入门店邀请码" },
                    model: {
                      value: _vm.invite_code,
                      callback: function ($$v) {
                        _vm.invite_code = $$v
                      },
                      expression: "invite_code",
                    },
                  }),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "margin-sm",
                      attrs: {
                        effect: "dark",
                        placement: "top",
                        content: "可在【企业设置-成员管理】页面获取",
                      },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "el-icon-question text-xl margin-left pointer",
                      }),
                      _vm._v("关于邀请码 "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleJoin },
                    },
                    [_vm._v("加 入")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { width: "fit-content", visible: _vm.createVisible },
          on: {
            "update:visible": function ($event) {
              _vm.createVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "dialog-title" }, [_vm._v("请创建门店")]),
          ]),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.createRules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入门店名称" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "contact" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入姓名" },
                    model: {
                      value: _vm.form.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contact", $$v)
                      },
                      expression: "form.contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入联系电话" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在地区", prop: "region_id" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.regionTree,
                      props: {
                        expandTrigger: "hover",
                        value: "id",
                        label: "title",
                      },
                    },
                    on: { change: _vm.regionChange },
                    model: {
                      value: _vm.form.region_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "region_id", $$v)
                      },
                      expression: "form.region_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "location" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请在下方定位门店详细地址" },
                    model: {
                      value: _vm.form.location,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "location", $$v)
                      },
                      expression: "form.location",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("tx-map", {
                    ref: "map",
                    attrs: {
                      width: "600px",
                      height: "360px",
                      location: _vm.form,
                    },
                    on: { location: _vm.locationChange },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("创 建")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("div", { staticClass: "upload" }, [
        _c("i", { staticClass: "el-icon-plus" }),
      ]),
      _c("div", { staticClass: "owner-name" }, [
        _c("div", { staticClass: "name" }, [_vm._v("创建新的门店")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }