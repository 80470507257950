var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user.pos_expire_status === 1
      ? _c("div", { staticClass: "text-red text-center margin-top" }, [
          _vm._v(" 门店套餐已过期，可以重新购买套餐 "),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "page" },
      [
        _vm._l(_vm.list, function (item) {
          return _c("div", { key: item.id, staticClass: "item" }, [
            _c("div", { staticClass: "head" }, [
              _c("h2", [_vm._v(_vm._s(item.title))]),
              _c("h3", [
                _vm._v(
                  _vm._s(item.price) + " / " + _vm._s(item.expand_day) + "个月"
                ),
              ]),
            ]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(item.content)),
            ]),
            _c("div", { staticClass: "sign" }, [
              _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.handleCode(item.id)
                    },
                  },
                },
                [_vm._v("立即开通")]
              ),
            ]),
          ])
        }),
        _c(
          "el-dialog",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              width: "fit-content",
              title: "套餐购买",
              visible: _vm.visible,
              "before-close": _vm.handleClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.visible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "padding-lr" }, [
              _c("img", {
                staticStyle: { width: "300px", height: "300px" },
                attrs: { src: _vm.url },
              }),
            ]),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }