var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-index flex align-center" }, [
    _c(
      "div",
      { staticClass: "login-left left-hidden left-transfer" },
      [_c("login-image", { attrs: { config: _vm.config } })],
      1
    ),
    _c("div", { staticClass: "login-right" }, [
      _c("div", { staticClass: "login-index" }, [_c("login-form")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }