var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "el-col",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth",
                  value: item.auth,
                  expression: "item.auth",
                },
              ],
              key: index,
              staticClass: "dashboard",
              attrs: { span: item.col },
            },
            [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click", placement: "bottom" },
                      on: { command: _vm.handleUpdate },
                    },
                    [
                      _c("i", { staticClass: "pointer el-icon-more" }),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: { index: index, action: "update" },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-dropdown-item",
                            {
                              attrs: {
                                command: { index: index, action: "hidden" },
                              },
                            },
                            [_vm._v("隐藏")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("micro-app", {
                attrs: {
                  name: item.name,
                  url: _vm.getEntry(item),
                  line: false,
                  baseRoute: "/web",
                },
                on: {
                  beforemount: function ($event) {
                    return _vm.beforeMount(item)
                  },
                  datachange: _vm.dataChange,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.temp.title,
            visible: _vm.formVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.temp } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.temp.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "title", $$v)
                      },
                      expression: "temp.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.temp.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "sort", $$v)
                      },
                      expression: "temp.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.temp.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "status", $$v)
                      },
                      expression: "temp.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }