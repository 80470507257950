"use strict";

var _interopRequireDefault = require("D:/www/voting/back-end/client/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
var arrayAuth = function arrayAuth(value, access) {
  if (value instanceof Array && value.length > 0) {
    return access.some(function (role) {
      return value.includes(role);
    });
  }
  throw new Error("need access! Like v-auth=\"['admin','editor']\"");
};
var stringAuth = function stringAuth(arg, access) {
  return access.some(function (role) {
    return role === arg;
  });
};
var wholeAuth = function wholeAuth(value, access) {
  if (value instanceof Array && value.length > 0) {
    return access.some(function (role) {
      return value.every(function (item) {
        return item === role;
      });
    });
  }
  throw new Error("need access! Like v-auth.whole=\"['admin','editor']\"");
};
var _default = {
  inserted: function inserted(el, binding, vnode) {
    var arg = binding.arg,
      value = binding.value,
      modifiers = binding.modifiers;
    var hasauth = true;
    var access = _store.default.getters && _store.default.getters.authorize;
    if (access.includes('all')) {
      return true;
    }
    if (arg) {
      hasauth = stringAuth(arg, access);
    } else if (value) {
      if (modifiers.whole) {
        hasauth = wholeAuth(value, access);
      } else if (value instanceof Array) {
        hasauth = arrayAuth(value, access);
      } else {
        hasauth = stringAuth(value, access);
      }
    } else {
      throw new Error("need access! Like v-auth:admin or v-auth=\"['admin','editor']\"!\n                      if you want to judge whole auth, please use whole modifier.\n                      such as v-auth.whole=\"['admin', 'editor']\"");
    }
    if (!hasauth) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
};
exports.default = _default;